import React from 'react'
import Helmet from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import { addCompletedLesson, removeCompletedLesson, isCompleted } from '../utils/storage';

import { Container, Row, Col, Button } from 'reactstrap'

import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

import { css } from 'react-emotion';

class LessonPageTemplate extends React.Component {

  lessonId;

  constructor(props){

    super(props);

    this.lessonId = this.props.data.markdownRemark.frontmatter.module + this.props.data.markdownRemark.frontmatter.lesson.toString();

    this.state = {
      completed: false
    };

  }

  componentDidMount(){

    let isComplete = isCompleted(this.lessonId);

    this.setState({
      completed: isComplete
    });

  }

  handleLessonComplete(){

    if(!this.state.completed){
      addCompletedLesson(this.lessonId);
      this.setState({
        completed: true
      });
    } else {
      removeCompletedLesson(this.lessonId);
      this.setState({
        completed: false
      }); 
    }

  }

  render() {
    const page = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = page.excerpt

    const embedStyle = css`
      .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
    `
    const pageStyles = css`
      h1, h2, h3, h4, h5, h6 {
        margin: 30px 0;
      }

      p {
        font-size: 1.2em;
      }

      img {
        display: block;
        margin: 5px auto 5px auto;
        max-width: 100%;
        width: auto;
        height: auto;
      }

      strong {
        font-weight: 700 !important;
      }

      ul {
        margin-left: 20px;
      }

      li {
        font-weight: 700;
        font-size: 1.1em;
        line-height: 1.8em;
      }

      .language-text {
        background-color: #f1f0f0 !important;
        padding: 5px !important;
        color: #000 !important;
        font-size: 15px;
        text-shadow: none !important;
        font-weight: bold;
      }

    `;

    return (
      
      <Layout location={this.props.location} title={page.frontmatter.title} back={'/angular' + page.frontmatter.module} complete={this.state.completed}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${page.frontmatter.title} | ${siteTitle}`}
        />

        {page.frontmatter.video != null ? (
          <Container fluid={true} style={{maxWidth: `1000px`, paddingTop: `20px`, paddingBottom: `20px`}}>
            <div className={embedStyle}  style={{backgroundColor: `#000`}}>
                <div className='embed-container'><iframe src={page.frontmatter.video} frameBorder='0' allowFullScreen></iframe></div>
            </div>
          </Container>
        ) : (
          <span></span>
        )}

        <Container fluid={true} style={{
          backgroundColor: `#efeeee`,
          paddingTop: `56px`,
          paddingBottom: `56px`,
          height: `100%`,
          minHeight: `100vh`
        }}>

          <article itemScope="itemscope" itemType="http://schema.org/BlogPosting" itemProp="blogPost"
            style={{
              backgroundColor: `#fdfbf3`,
              padding: `35px`,
              maxWidth: `1000px`,
              margin: `0 auto`
            }}
          >

            <Row>
              <Col xs={{size: 4}}>
                {
                  page.frontmatter.lesson > 0 &&
                  <Button style={{width: `100%`}} href={withPrefix('/angular' + page.frontmatter.module + '/' + (page.frontmatter.lesson - 1).toString())}><FaChevronLeft style={{position: `relative`, top: `-2px`}} /> Previous Lesson</Button>
                }
                
              </Col>

              <Col xs={{size: 4, offset: 4}}>
                {
                  page.frontmatter.title != 'Conclusion' &&
                  <Button style={{width: `100%`}} href={withPrefix('/angular' + page.frontmatter.module + '/' + (page.frontmatter.lesson + 1).toString())}>Next Lesson <FaChevronRight style={{position: `relative`, top: `-2px`}} /></Button>
                }
              </Col>
            </Row>

            <Row>
              <Col>
                <div className={pageStyles} dangerouslySetInnerHTML={{ __html: page.html }} />
              </Col>
            </Row>

            <Row>
              <Col xs={{size: 4}}>
                {
                  page.frontmatter.lesson > 0 &&
                  <Button style={{width: `100%`}} href={withPrefix('/angular' + page.frontmatter.module + '/' + (page.frontmatter.lesson - 1).toString())}><FaChevronLeft style={{position: `relative`, top: `-2px`}} /> Previous Lesson</Button>
                }
                
              </Col>

              <Col xs={{size: 4, offset: 4}}>
                {
                  page.frontmatter.title != 'Conclusion' &&
                  <Button style={{width: `100%`}} href={withPrefix('/angular' + page.frontmatter.module + '/' + (page.frontmatter.lesson + 1).toString())}>Next Lesson <FaChevronRight style={{position: `relative`, top: `-2px`}} /></Button>
                }
              </Col>
            </Row>

            <Button outline={!this.state.completed} block color="success" style={{marginTop: `20px`}} onClick={() => this.handleLessonComplete()}>
              { this.state.completed ? 'Mark as Incomplete' : 'Mark as Complete'}
            </Button>

          </article>

        </Container>        

      </Layout>
    )
  }
}

export default LessonPageTemplate

export const pageQuery = graphql`
  query LessonPageBySlug($module: String!, $lesson: Int!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      frontmatter: { 
        module: { eq: $module },
        lesson: { eq: $lesson }
      }
    ) {
      id
      excerpt
      html
      frontmatter {
        title
        video
        type
        module
        lesson
      }
    }
  }
`